import React from 'react'

const Hero = () => {
  return (
    <section className="site-hero">
      <div className="site-hero-callout">
        <p className="site-hero-callout__action">Help Galactic Rex escape the clutches of evil in this space adventure!</p>
        <a href="https://itunes.apple.com/us/app/galactic-rex/id1456649127?mt=8" className="app-store-badge"></a>
      </div>
    </section>
  )
}

export default Hero